import React, { useEffect, useState } from 'react';
import './welcome.css'; // CSS file for styling
import { onAuthStateChanged } from 'firebase/auth';
import { FBAUTH } from '../../fb/init';
import { useNavigate } from "react-router-dom";

const WelcomeScreen = () => {
  const [showAnimation, setShowAnimation] = useState(false);
  const navigate = useNavigate();

  const init = () => {
    setShowAnimation(true);
    setTimeout(async () => {
      const user = await localStorage.getItem("user");
      if (user) {
        navigate("/home");
      } else {
        navigate("/login")
      }
    }, 3000)
  }

  useEffect(() => {
    init();
  }, []);

  return (
    <div className={`center-text ${showAnimation ? 'animate' : ''}`}>
      <h1 className='main-text'>Kernels: Save Ideas</h1>
    </div>
  );
};

export default WelcomeScreen;
