export const urlRegExp = /(https?|ftp|smtp)\:\/\/(www.)?([\w\-]+)(.\w+)*/gi;

export function extractLinks(text) {
  const links = [];
  if (text == null || text == undefined) return links;
  console.log('extract links', text);
  text.toLowerCase().replace(urlRegExp, (link) => {
    let els = null;
    while (els === null) {
      els = urlRegExp.exec(link);
    }
    if (els.length > 3) {
      links.push(els[3]);
    }
    return link;
  });
  console.log('Extract Links', links);
  return links;
}

export function uniqueTags(tags) {
  return (tags || []).reduce((prev, current) => {
    if (prev.some(({ id }) => id === current.id)) {
      return prev;
    }
    return [...prev, current];
  }, []);
}

export const updateAmazonLinks = (text, amazonRef, amazonTag) => {
  const regex = /(https:\/\/www\.amazon\.com\/[^\s]+)/gi; // Regular expression to match Amazon URLs

  const updatedText = text?.replace(regex, (match) => {
    const dpIndex = match.indexOf('/dp/');
    if (dpIndex !== -1) {
      const endIndex = match.indexOf('/', dpIndex + 4);
      if (endIndex !== -1) {
        const baseURL = match.substring(0, endIndex);
        return `${baseURL}/ref=${amazonRef}&tag=${amazonTag}`;
      }
    }
    return match;
  });

  return updatedText;
};
