import { createBrowserRouter } from "react-router-dom";
import LoginScreen from "./pages/auth/LoginScreen";
import WelcomeScreen from "./pages/other/WelcomeScreen";
import HomeScreen from "./pages/home/HomeScreen";
import TagScreen from "./pages/tag/TagScreen";
import ProfileScreen from "./pages/profile/ProfileScreen";
import SettingScreen from "./pages/setting/SettingScreen";
import NoteDetail from "./pages/note/NoteDetail";
import NewNote from "./pages/note/NewNote";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <WelcomeScreen />,
  },
  {
    path: "/login",
    element: <LoginScreen />,
  },
  {
    path: "/home",
    element: <HomeScreen />,
  },
  {
    path: "/tag",
    element: <TagScreen />,
  },
  {
    path: "/profile",
    element: <ProfileScreen />,
  },
  {
    path: "/setting",
    element: <SettingScreen />,
  },
  {
    path: "/note-detail",
    element: <NoteDetail />,
  },
  {
    path: "/create-note",
    element: <NewNote />,
  },
]);
