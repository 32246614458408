import { Button, Card, Col, Input, Modal, Popover, Row, message } from "antd";
import TextArea from 'antd/es/input/TextArea';
import "firebase/auth";
import moment from "moment";
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { getUserData } from "../../fb/auth";
import { createNewNote, getUserNotes, removeNoteData, updateNoteData } from "../../fb/note";
import { addMainTag, getAllTags } from "../../services/MainTagService";
import { GetUserInfo, SyncTags } from "../../services/GlobalService";
import { getRandomColor } from "../../services/CommonService";
import HeaderContainer from "../container/HeaderContainer";
import NoteCard from "./NoteCard";
import "./style.css";
import { extractLinks, updateAmazonLinks } from "../../services/ExtractLinks";

export default function HomeScreen() {

  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();

  const [loading, setLoading] = useState(false);
  const [noteData, setNoteData] = useState([]);
  const [mainTagData, setMainTagData] = useState([]);
  const [subTagData, setSubTagData] = useState([]);
  const [selectedTagData, setSelectedTagData] = useState([]);
  const [subStatus, setSubStatus] = useState(false);
  const [selectedNoteData, setSelectedNoteData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [userData, setUserData] = useState();
  const [dateInfo, setDateInfo] = useState([]);
  const [tagClickStatus, setTagClickStatus] = useState(false);

  const [currentYear, setCurrentYear] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(false);
  const [dataSourceCords, setDataSourceCords] = useState([]);

  const [monthDate, setMonthDate] = useState(moment().format("MMMM"));
  const [yearDate, setYearDate] = useState(new Date().getFullYear());
  const [newNoteVisible, setNewNoteVisible] = useState(false);

  var monthArray = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  const [noteTitle, setNoteTitle] = useState("");
  const [noteContent, setNoteContent] = useState("");
  const [tagData, setTagData] = useState([]);
  const [newTag, setNewTag] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [selectedTag, setSelectedTag] = useState([]);


  const [infoNoteVisible, setInfoNoteVisible] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const popoverContent = () => {
    return (
      <div>
        <Button type="text" onClick={() => setEditMode(true)}>Edit</Button>
        <Button type="text">Share</Button>
        <Button type="text" onClick={() => removeNote()}>Delete</Button>
      </div>
    )
  }


  const getNote = (user) => {
    setLoading(true);
    getUserNotes(user)
      .then(async (response) => { // get user notes from firebase
        const queryData = response.docs;
        // setNoteData(response);
        var listData = [];
        var dateData = [];

        for (let i = 0; i < queryData.length; i++) {
          const doc = queryData[i];
          const data = doc.data();
          data.id = doc.id;
          const date = data.createdDate.toDate();
          const month = new Date(date).getMonth();
          const year = new Date(date).getFullYear();

          data.date = year + ',' + month;
          data.year = year;
          data.month = month;

          if (!dateData.find((element) => element.date === year + ',' + month)) {
            dateData.push({
              date: year + ',' + month,
              year: year,
              month: month,
            });
          }

          listData.push(data);
        }

        setNoteData(listData);
        setSelectedNoteData(listData);
        setDateInfo(dateData);
        setLoading(false);
      })
      .catch((err) => {
        console.log("error", err)
        messageApi.error("Something went wrong, please try again later!");
        setLoading(false);
      });
  }

  const getUser = (user) => {
    getUserData(user)
      .then((response) => {
        setUserData(response);
        setCurrentYear(response.yearTagVisible);
        setCurrentMonth(response.monthTagVisible);
      })
      .catch((err) => {
        console.log(err);
      })
  }

  const getTag = async (user) => {
    const tags = await getAllTags(user, true, true);
    if (tags) {
      const mainTags = tags.filter(t => t.parent === true);
      const subTags = tags.filter(t => t.parent === false);
      setMainTagData(mainTags);
      setSelectedTagData(mainTags);
      setSubTagData(subTags);
      setTagData(tags);
    }
  }

  const init = async () => {
    const user = GetUserInfo();
    const b_result = await SyncTags(user);
    if (b_result) {
      console.log('Synced with Typesense successfully!');
    } else {
      console.log('Failed to sync with Typesense!');
    }

    if (user) {
      getNote(user);
      getUser(user);
      getTag(user);
    }
    else navigate("/");
  }

  useEffect(() => {
    init()
  }, [])

  const getSubTagInfo = (id) => {
    const result = [];
    setTagClickStatus(true);
    setCurrentYear(false);
    setCurrentMonth(false);

    var parent = mainTagData.find(element => element.id === id);
    subTagData.map((sub) => {
      if (sub.parentId === id) {
        result.push(sub);
      }
    })

    if (result.length != 0) {
      setSubStatus(true);
      setSelectedTagData(result);
      searchByTag([parent]);
    } else {
      var tagInfo = mainTagData.find(element => element.id === id);
      setSubStatus(true);
      if (tagInfo) {
        setSelectedTagData([tagInfo]);
        searchByTag([tagInfo]);
      } else {
        tagInfo = subTagData.find(element => element.id === id);
        setSelectedTagData([tagInfo]);
        searchByTag([tagInfo]);
      }
    }
  }

  const filterByYear = () => {
    setTagClickStatus(true);
    setSelectedTagData([]);
    setCurrentMonth(false);
    setSubStatus(true);

    var data = noteData;
    data = data.filter(element => new Date(element.createdDate.toDate()).getFullYear() == yearDate);
    setSelectedNoteData(data);
  }

  const filterByMonth = () => {
    setTagClickStatus(true);
    setSelectedTagData([]);
    setCurrentYear(false);
    setSubStatus(true);

    var data = noteData;
    var index = monthArray.findIndex(element => element == monthDate);
    // data.filter(element => element.currentMonth === true);
    data = data.filter(element => new Date(element.createdDate.toDate()).getMonth() == index);

    setSelectedNoteData(data);
  }

  const searchByTag = (tags) => {
    var noteResult = [];
    tags.map((tag) => {
      noteData.map((note) => {
        if (JSON.parse(note.tag).find(element => element.id === tag.id)) {
          noteResult.push(note);
        }
      })
    })
    if (noteResult.length !== 0) {
      setSelectedNoteData(noteResult);
    } else {
      setSelectedNoteData([]);
    }
  }

  const onCancelSubTag = () => {
    setTagClickStatus(false);
    setSubStatus(false);
    setSelectedTagData(mainTagData);
    setSelectedNoteData(noteData);
    setCurrentYear(userData.yearTagVisible);
    setCurrentMonth(userData.monthTagVisible);
  }

  const goNoteDetail = (id) => {
    navigate("NoteDetail", {
      noteID: id
    })
  }

  const goNoteEdit = (id) => {
    navigate("NoteEdit", {
      noteID: id
    })
  }

  const removeNote = (id) => {
    setLoading(true);
    const param = id ? id : selectedNote.id;

    removeNoteData(param)
      .then((response) => {
        setInfoNoteVisible(false);
        setNewNoteVisible(false);
        init();
      })
      .catch((err) => {
        console.log(err);
      })
  }

  const searchByText = (text) => {
    setSearchText(text);
    var searchNote = [];

    noteData.map((note) => {
      if (note.title.toLowerCase().includes(text.toLowerCase()) || note.content.toLowerCase().includes(text.toLowerCase())) {
        searchNote.push(note);
      }
    })
    setSelectedNoteData(searchNote);
  }

  const content = () => {
    return (
      searchData.length > 0 ?
        <div className='pop-tag-container'>
          {
            searchData.map((search, index) => (
              <Button
                key={index}
                type="link"
                onClick={() => addExistingTag(search)}
              >
                {search.showText}
              </Button>
            ))
          }
        </div>
        :
        <div className='pop-tag-container'>
          {
            newTag ?
              <Button
                type="link"
                onClick={() => addNewTag()}
              >
                Add Tag {newTag}
              </Button>
              :
              <></>
          }
        </div>
    )
  }

  const searchInfo = (e) => {
    if (e != "") {
      const result = tagData.filter((t) => !t.hasSub && t.showText.toLowerCase().includes(e.toLowerCase()) && !selectedTag.find((e) => e.id === t.id));
      setSearchData(result);
    } else {
      setSearchData([])
    }
  }

  const addExistingTag = (data) => {
    if (selectedTag.find((e) => e.id === data.id)) return;
    if (data.parent === true) {
      setSelectedTag((origin) => [...origin, data]);
    } else {
      if (!selectedTag.find(element => element.id === data.parentId)) {
        const mTag = mainTagData.find((t) => t.id === data.parentId)
        setSelectedTag((origin) => [...origin, mTag]);
      }
      setSelectedTag((origin) => [...origin, data]);
    }
    setNewTag("");
  }

  const addNewTag = async () => {
    setLoading(true);
    const data = {
      color: getRandomColor(),
      text: newTag,
      user: userData.id
    };
    const nTag = await addMainTag(data);
    if (nTag) {
      setSelectedTag((origin) => [...origin, { fillColor: data.color, hasSub: false, parent: true, id: nTag.id, text: newTag }]);
      setMainTagData([...mainTagData, { fillColor: data.color, hasSub: false, parent: true, id: nTag.id, text: newTag }])
      setNewTag("");
      setLoading(false);
    } else {
      messageApi.error("Something went wrong, please try again later!");
    }
  }

  const saveBack = async (stayStatus) => {
    if (noteTitle == "" && noteContent != "") {
      messageApi.warning("Missing Note Title");
      return;
    }

    if (noteTitle != "" && noteContent == "") {
      messageApi.warning("Missing note content");
    }

    if (noteTitle != "" && noteContent != "") {
      setLoading(true);
      const sel_tags = selectedTag.map((e) => { return { color: e.fillColor, text: e.text, id: e.id } });
      const amazonRef = 'noism'; // Replace with your new 'ref' value
      const amazonTag = 'kernels0db-20'; // Replace with your new 'tag' value
      const updatedText = updateAmazonLinks(noteContent, amazonRef, amazonTag);
      const linkTags = extractLinks(noteContent);
      await Promise.all(linkTags.map(async (linkTag) => await addMainTag({ text: linkTag, user: userData.id }))).then(async (autoTags) => {
        const selectedTagData = JSON.stringify([...sel_tags, ...autoTags.filter(Boolean)]);
        const insertData = {
          user: userData.id,
          title: noteTitle,
          content: updatedText,
          tag: selectedTagData,
          currentYear: currentYear,
          currentMonth: currentMonth,
          createdDate: new Date()
        }

        createNewNote(insertData)
          .then((response) => {
            setLoading(false);
            setNewNoteVisible(false);
            init();
          })
          .catch((err) => {
            console.log(err);
          })
      });
    }
  }

  const updateNote = async () => {
    if (noteTitle && noteContent) {
      if (selectedNote?.noteType == "image") {
        const sel_tags = selectedTag.map((e) => { return { color: e.fillColor, text: e.text, id: e.id } });
        const selectedTagData = JSON.stringify(sel_tags);
        const param = {
          title: noteTitle,
          tag: selectedTagData,
          currentYear: currentYear,
          currentMonth: currentMonth,
        }

        updateNoteData(param, selectedNote.id)
          .then((response) => {
            setLoading(false);
            messageApi.success("Note has been updated!");
            init();
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          })
      } else {

        setLoading(true);
        const sel_tags = selectedTag.map((e) => { return { color: e.fillColor, text: e.text, id: e.id } });
        const amazonRef = 'noism'; // Replace with your new 'ref' value
        const amazonTag = 'kernels0db-20'; // Replace with your new 'tag' value
        const updatedText = updateAmazonLinks(noteContent, amazonRef, amazonTag);
        const linkTags = extractLinks(noteContent);
        await Promise.all(linkTags.map(async (linkTag) => await addMainTag({ text: linkTag, user: userData.id }))).then(async (autoTags) => {
          const selectedTagData = JSON.stringify([...sel_tags, ...autoTags.filter(Boolean)]);
          const param = {
            title: noteTitle,
            content: updatedText,
            tag: selectedTagData,
            currentYear: currentYear,
            currentMonth: currentMonth,
          }

          updateNoteData(param, selectedNote.id)
            .then((response) => {
              setLoading(false);
              messageApi.success("Note has been updated!");
              setInfoNoteVisible(false);
              init();
            })
            .catch((err) => {
              messageApi.error("Something went wrong, please try again later!");

              setLoading(false);
              console.log(err);
            })
        });
      }
    } else {
      messageApi.warning("Please input all the contents");
    }
  }

  return (
    <HeaderContainer loading={loading}>
      {contextHolder}
      <Row>
        <Col
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}
          xs={{ span: 22, offset: 1 }}
        >
          <Row className="home-top-search-container">
            <Col
              span={24}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
              }}
            >
              <h1 className="page-title">My Notes</h1>

              <Button
                type='link'
                style={{ height: "100%" }}
                onClick={() => {
                  setNoteTitle("");
                  setNoteContent("");
                  setSelectedTag([]);
                  setNewNoteVisible(true)
                }}
              >
                <img src={require("../../assets/images/add-icon.png")} className='add-button' alt="add-button" />
              </Button>
            </Col>
            <Col span={24}>
              <Input
                className="search-input"
                placeholder="Search text or tag"
                onPressEnter={(e) => searchByText(e.target.value)}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
              <img src={require("../../assets/images/search.png")} className="search-icon" alt="search-icon" />
            </Col>
          </Row>
        </Col>

        <Col span={22} offset={1}>
          <div className='note-tag-container home-top-tag-container'>
            {
              currentYear === true &&
              <div
                onClick={() => filterByYear()}
                className="note-tag-button"
                style={{ backgroundColor: "#FFA877" }}
              >
                <span style={{ color: "white" }}>{yearDate}</span>
              </div>
            }
            {
              currentMonth === true &&
              <div
                key={"note-btn"}
                onClick={() => filterByMonth()}
                className="note-tag-button"
                style={{ backgroundColor: "#FDF2EC", borderColor: "#FFA877", borderWidth: 1, bottom: 0 }}
              >
                <span style={{ color: "#FFA877" }}>{monthDate}</span>
              </div>
            }
            {
              selectedTagData?.map((tag, key) => (
                <div
                  key={"note-btn-" + key}
                  onClick={() => getSubTagInfo(tag.id)}
                  className="note-tag-button"
                  style={{ backgroundColor: tag?.fillColor, borderColor: tag?.fillColor.replace("88", ""), borderWidth: 1, bottom: 0 }}
                >
                  <span style={{ color: "white" }}>{tag?.text} </span>
                </div>
              ))
            }
            {
              subStatus === true &&
              <div
                className="clear-button"
                onClick={() => onCancelSubTag()}
              >
                <span>x</span>
              </div>
            }
          </div>
        </Col>
      </Row>
      <Row className="note-card-wrapper" style={{ marginTop: 60 }}>
        {
          selectedNoteData.length > 0 ?
            selectedNoteData.map((notes, key) => (
              <NoteCard
                key={"note-" + key}
                note={notes}
                removeNote={removeNote}
                goToEdit={(note) => navigate("/note-detail", { state: { note: note, editable: true } })}
                goToDetail={() => {
                  const tags = JSON.parse(notes.tag);
                  setEditMode(false)
                  setSelectedTag(tags.map((e) => { return { fillColor: e.color, showText: e.text, text: e.text, id: e.id } }))
                  setNoteTitle(notes.title)
                  setNoteContent(notes.content)
                  setSelectedNote(notes)
                  setInfoNoteVisible(true)
                }}
              />
            ))
            :
            <></>
        }
      </Row>

      <Modal
        open={newNoteVisible}
        onCancel={() => setNewNoteVisible(false)}
        footer={null}
        className="new-note-modal"
        width="800px"
        closeIcon={<></>}
      >

        <Row className="note-detail-container">
          <Col
            lg={{
              span: 24
            }}
          >
            <Card
              size="large"
            >
              <Row>
                <Col span={24} className='note-top-container'>
                  <Button onClick={() => setNewNoteVisible(false)} type="link">
                    <img src={require("../../assets/images/back.png")} className='back-icon' alt="back-icon" />
                  </Button>

                  <span className='current-date-text'>{moment().format('MMMM Do YYYY')}</span>

                  <Button type="primary" className="save-note-btn" onClick={() => saveBack()}>
                    Save
                  </Button>

                </Col>
              </Row>
              <div className='detail-container'>
                <Row>
                  <Col span={24}>
                    <Input
                      className='note-title'
                      placeholder="Title"
                      value={noteTitle}
                      onChange={(e) => setNoteTitle(e.target.value)}
                    />
                  </Col>

                  <Col span={24}>
                    <TextArea
                      rows={10}
                      placeholder='Enter your note detail'
                      value={noteContent}
                      onChange={(e) => setNoteContent(e.target.value)}
                    />
                  </Col>

                  <div className='new-note-tag-container'>

                    {
                      currentYear === true &&
                      <div
                        className="tag-button"
                        style={{ backgroundColor: "#FFA877" }}
                      >
                        <span style={{ color: "white" }}>{yearDate}</span>
                      </div>
                    }
                    {
                      currentMonth === true &&
                      <div
                        className="tag-button"
                        style={{ backgroundColor: "#FDF2EC", borderColor: "#FFA877", borderWidth: 1, bottom: 0 }}
                      >
                        <span style={{ color: "#FFA877" }}>{monthDate}</span>
                      </div>
                    }


                    {
                      selectedTag?.map((tag, key) => (
                        <div
                          key={'new-note-tag-' + key}
                          className="note-tag-button"
                          style={{ backgroundColor: tag?.fillColor, borderColor: tag?.fillColor.replace("88", ""), borderWidth: 1, bottom: 0 }}
                        >
                          <span style={{ color: "white" }}>{tag?.text} </span>
                        </div>
                      ))
                    }

                    <Popover
                      content={content}
                      trigger="hover"
                    >
                      <Input
                        value={newTag}
                        placeholder="Add tag"
                        className="new-tag-input"
                        onChange={(e) => {
                          setNewTag(e.target.value)
                          searchInfo(e.target.value)
                        }}
                      />
                    </Popover>
                  </div>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
      </Modal>
      <Modal
        open={infoNoteVisible}
        onCancel={() => setInfoNoteVisible(false)}
        footer={null}
        className="new-note-modal"
        width="800px"
        closeIcon={<></>}
      >

        <Row className="note-detail-container">
          <Col span={24}>
            <Card
              size="large"
            >
              <Row>
                <Col span={24} className='note-top-container'>
                  <Button onClick={() => navigate("/home")} type="link">
                    <img src={require("../../assets/images/back.png")} className='back-icon' />
                  </Button>

                  <span className='current-date-text'>{moment().format('MMMM Do YYYY')}</span>

                  {
                    editMode ?
                      <Button type="primary" onClick={() => updateNote()} className='update-button'>
                        Save
                      </Button>
                      :
                      <Popover
                        placement="bottom"
                        content={(popoverContent)}
                        trigger="click"
                      >
                        <Button type="link"><img className='right-icon' src={require("../../assets/images/pop_dot.png")} alt='right-icon' /></Button>
                      </Popover>
                  }

                </Col>
              </Row>
              <div className='detail-container'>
                {
                  editMode ?
                    <Row>
                      <Col span={24}>
                        <Input
                          className='note-title'
                          value={noteTitle}
                          onChange={(e) => setNoteTitle(e.target.value)}
                        />
                      </Col>

                      <Col span={24}>
                        <TextArea
                          rows={10}
                          placeholder='Enter your note detail'
                          value={noteContent}
                          onChange={(e) => setNoteContent(e.target.value)}
                        />
                      </Col>

                      <div className='new-note-tag-container'>
                        {
                          currentYear === true &&
                          <div
                            className="tag-button"
                            style={{ backgroundColor: "#FFA877" }}
                          >
                            <span style={{ color: "white" }}>{yearDate}</span>
                          </div>
                        }
                        {
                          currentMonth === true &&
                          <div
                            className="tag-button"
                            style={{ backgroundColor: "#FDF2EC", borderColor: "#FFA877", borderWidth: 1, bottom: 0 }}
                          >
                            <span style={{ color: "#FFA877" }}>{monthDate}</span>
                          </div>
                        }
                        {
                          selectedTag?.map((tag) => (
                            <div
                              key={'info-tag-' + tag.parent ? 'main-' : '' + tag.id}
                              className="tag-button"
                              style={{ backgroundColor: tag.fillColor, borderColor: tag.fillColor.replace("88", ""), borderWidth: 1, bottom: 0 }}
                            >
                              <span style={{ color: "white" }}>{tag.text} </span>
                            </div>
                          ))
                        }

                        <Popover
                          content={content}
                          trigger="hover"
                        >
                          <Input
                            value={newTag}
                            placeholder="Add tag"
                            className="new-tag-input"
                            onChange={(e) => {
                              setNewTag(e.target.value)
                              searchInfo(e.target.value)
                            }}
                          />
                        </Popover>
                      </div>
                    </Row>
                    :
                    <Row>
                      <Col span={24}>
                        <h1 className='note-title'>{selectedNote?.title}</h1>
                      </Col>

                      <Col span={24} style={{ marginTop: 20 }}>
                        {
                          selectedNote?.noteType == "image" ?
                            <img src={selectedNote.imageUrl} style={{ width: "100%" }} />
                            :
                            <p>{selectedNote?.content}</p>
                        }
                        <div className='new-note-tag-container'>
                          {
                            currentYear === true &&
                            <div
                              className="tag-button"
                              style={{ backgroundColor: "#FFA877" }}
                            >
                              <span style={{ color: "white" }}>{yearDate}</span>
                            </div>
                          }
                          {
                            currentMonth === true &&
                            <div
                              className="tag-button"
                              style={{ backgroundColor: "#FDF2EC", borderColor: "#FFA877", borderWidth: 1, bottom: 0 }}
                            >
                              <span style={{ color: "#FFA877" }}>{monthDate}</span>
                            </div>
                          }
                          {
                            selectedTag?.map((tag) => (
                              <div
                                key={'detail-tag-' + tag.id + (tag.parent ? '-main' : '')}
                                className="tag-button"
                                style={{ backgroundColor: tag.fillColor, borderColor: tag.fillColor.replace("88", ""), borderWidth: 1, bottom: 0 }}
                              >
                                <span style={{ color: "white" }}>{tag.text} </span>
                              </div>
                            ))
                          }
                        </div>
                      </Col>
                    </Row>
                }
              </div>
            </Card>
          </Col>
        </Row>
      </Modal>
    </HeaderContainer>
  )
}
