const SubTagSchema = {
  name: 'subTags',
  fields: [
    {
      name: 'color',
      type: 'string',
      facet: false,
    },
    {
      name: 'text',
      type: 'string',
      facet: false,
      sort: true,
    },
    {
      name: 'fid',
      type: 'string',
      facet: false,
      sort: false,
    },
    {
      name: 'mainTag',
      type: 'string',
      facet: false,
      index: true,
    },
  ],
};

export default SubTagSchema;
