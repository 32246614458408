import { Button, Card, Col, Input, Popover, Row, message } from 'antd';
import React, { useEffect, useState } from 'react'
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import HeaderContainer from '../container/HeaderContainer';
import { ReactTinyLink } from "react-tiny-link";
import { createMainTag, createNewNote, getNoteDetail, getSubTag, getTagData, removeNoteData, updateNoteData } from '../../fb/note';
import moment from 'moment';
import './style.css';
import { LinkPreview } from '@dhaiwat10/react-link-preview';

export default function NoteDetail() {

  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const { TextArea } = Input;

  const { state } = useLocation();

  const [userData, setUserData] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const [currentYear, setCurrentYear] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(false);
  const [newTag, setNewTag] = useState("");
  const [selectedTag, setSelectedTag] = useState([]);
  const [linkData, setLinkData] = useState([]);
  const [bottomHeight, setBottomHeight] = useState(100);
  const [yearDate, setYearDate] = useState("");
  const [monthDate, setMonthDate] = useState("");
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [noteData, setNoteData] = useState(null);
  const [noteTitle, setNoteTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [noteContent, setNoteContent] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [tagData, setTagData] = useState([]);
  const [subTagData, setSubTagData] = useState([]);

  var monthArray = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];


  const getTag = (user) => {

    setTagData([]);
    setSubTagData([]);

    getTagData(user)
      .then(async (response) => {
        await Promise.all(response.map(async (doc) => {
          const data = doc;
          try {
            getSubTag(doc.id)
              .then((response) => {

                const checkboxData = {
                  id: doc.id,
                  text: data.text,
                  fillColor: data.color,
                  parent: null,
                  showText: data.text
                };
                setTagData((origin) => [...origin, checkboxData]);

                response.map(async (docs) => {
                  const subData = docs;
                  const subcheckboxData = {
                    id: docs.id,
                    text: subData.text,
                    fillColor: subData.color,
                    parentID: doc.id,
                    parent: data.text,
                    showText: data.text + "/" + subData.text
                  };
                  setTagData((origin) => [...origin, subcheckboxData]);
                })
              })
              .catch((err) => {
                console.log(err);
              })
          } catch (e) {
            setLoading(false);
            messageApi.error("Something went wrong, please try again later!");
          }
        }))
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        messageApi.error("Something went wrong, please try again later!");
      })
  }


  const init = async () => {
    const user = await localStorage.getItem("user");
    if (user) {
      setUserData(user);
      if (state) {
        const { note } = state;
        setLoading(true);
        getTag(user);
        getNoteDetail({ id: note.id })
          .then((response) => {
            setNoteData(response);

            setNoteTitle(response.title)
            setNoteContent(response.content)
            const { editable } = state;
            if (editable) {
              setEditMode(true);
            }

            var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
            setLinkData(response.content.match(urlRegex));

            setCurrentYear(response.currentYear);
            setCurrentMonth(response.currentMonth);

            const date = response.createdDate.toDate();
            const month = new Date(date).getMonth();
            const year = new Date(date).getFullYear();

            setMonthDate(monthArray[month]);
            setYearDate(year);

            setSelectedTag(JSON.parse(response.tag));
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            messageApi.error("Something went wrong, please try again later!");
          })
      } else {
        navigate("/home");
      }

    }
    else navigate("/");
  }

  useEffect(() => {
    init();
  }, [])

  const popoverContent = () => {
    return (
      <div>
        <Button type="text" onClick={() => setEditMode(true)}>Edit</Button>
        <Button type="text">Share</Button>
        <Button type="text" onClick={() => removeNote()}>Delete</Button>
      </div>
    )
  }


  const updateNote = () => {
    if (noteTitle && noteContent) {
      if (noteData?.noteType == "image") {
        setLoading(true);
        const selectedTagData = JSON.stringify(selectedTag);
        const param = {
          title: noteTitle,
          tag: selectedTagData,
          currentYear: currentYear,
          currentMonth: currentMonth,
        }

        updateNoteData(param, noteData.id)
          .then((response) => {
            setLoading(false);
            messageApi.success("Note has been updated!");
            navigate("/home");
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          })
      } else {

        setLoading(true);
        const selectedTagData = JSON.stringify(selectedTag);

        const param = {
          title: noteTitle,
          content: noteContent,
          tag: selectedTagData,
          currentYear: currentYear,
          currentMonth: currentMonth,
        }

        updateNoteData(param, noteData.id)
          .then((response) => {
            setLoading(false);
            messageApi.success("Note has been updated!");
            navigate("/home");
          })
          .catch((err) => {
            messageApi.error("Something went wrong, please try again later!");

            setLoading(false);
            console.log(err);
          })

      }
    } else {
      messageApi.warning("Please input all the contents");
    }
  }


  const removeNote = () => {
    setLoading(true);

    removeNoteData(noteData.id)
      .then((response) => {
        setLoading(false);
        navigate("/home")
      })
      .catch((err) => {
        console.log(err);
        messageApi.error("Something went wrong while remove note, please try again later!");
        setLoading(false);
      })
  }





  const content = () => {
    return (
      searchData.length > 0 ?
        <div className='pop-tag-container'>
          {
            searchData.map((search, index) => (
              <Button
                key={index}
                type="link"
                onClick={() => addExistingTag(search)}
              >
                {search.showText}
              </Button>
            ))
          }
        </div>
        :
        <div className='pop-tag-container'>
          {
            newTag ?
              <Button
                type="link"
                onClick={() => addNewTag()}
              >
                Add Tag {newTag}
              </Button>
              :
              <></>
          }
        </div>
    )
  }

  const searchInfo = (e) => {
    const result = [];
    if (e != "") {
      tagData.map((tag) => {
        if (tag.showText.toLowerCase().includes(e.toLowerCase()) === true) {
          result.push(tag);
        }
      })
      setSearchData(result);
    } else {
      setSearchData([])
    }
  }


  const addExistingTag = (data) => {
    if (data.parent == null) {
      const tags = {
        text: data.showText,
        color: data.fillColor,
        id: data.id
      }
      setSelectedTag((origin) => [...origin, tags]);
    } else {
      if (!selectedTag.find(element => element.id === data.parentID)) {
        var tags = {
          text: data.parent,
          color: data.fillColor.replace('88', ''),
          id: data.parentID
        }
        setSelectedTag((origin) => [...origin, tags]);
      }
      var tags = {
        text: data.text,
        color: data.fillColor,
        id: data.id
      }
      setSelectedTag((origin) => [...origin, tags]);
    }

    setNewTag("");
  }

  const getRandomColor = () => {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  const addNewTag = () => {
    setLoading(true);
    const color = getRandomColor();

    const data = {
      color: color,
      text: newTag,
      user: userData
    }

    createMainTag(data)
      .then((response) => {
        data.id = response;
        setSelectedTag((origin) => [...origin, data]);
        setNewTag("");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        messageApi.error("Something went wrong, please try again later!");
      })

  }





  const saveBack = (stayStatus) => {
    if (noteTitle == "" && noteContent != "") {
      messageApi.warning("Missing Note Title");
      return;
    }

    if (noteTitle != "" && noteContent == "") {
      messageApi.warning("Missing note content");

    }

    if (noteTitle != "" && noteContent != "") {
      setLoading(true);
      const selectedTagData = JSON.stringify(selectedTag);
      const insertData = {
        user: userData,
        title: noteTitle,
        content: noteContent,
        tag: selectedTagData,
        currentYear: currentYear,
        currentMonth: currentMonth,
        createdDate: new Date()
      }

      createNewNote(insertData)
        .then((response) => {
          console.log(response);
          setLoading(false);
          navigate("/home");
        })
        .catch((err) => {
          console.log(err);
        })
    }
  }


  const customFetcher = async (url) => {
    try {
      const response = await fetch(`http://localhost:8000/v2?url=${url}`);
      const json = await response.json();
      return json.metadata;
    }
    catch (err) {
      console.log("error", err);
    }
  };


  return (
    <HeaderContainer loading={loading}>
      {contextHolder}

      <Row className="note-detail-container">
        <Col
          xs={{
            span: 22,
            offset: 1
          }}
          lg={{
            span: 12,
            offset: 6
          }}
        >
          <Card
            size="large"
          >
            <Row>
              <Col span={24}>
                <img src={require("../../assets/images/wave.png")} className='wave-image' />
              </Col>
              <Col span={24} className='note-top-container'>
                <Button onClick={() => navigate("/home")} type="link">
                  <img src={require("../../assets/images/back.png")} className='back-icon' />
                </Button>

                <span className='current-date-text'>{moment().format('MMMM Do YYYY')}</span>

                <Popover
                  placement="bottom"
                  content={(popoverContent)}
                  trigger="click"
                >
                  <Button type="link"><img className='right-icon' src={require("../../assets/images/pop_dot.png")} /></Button>
                </Popover>

              </Col>
            </Row>
            <div className='detail-container'>
              {
                editMode ?
                  <Row>
                    <Col span={24}>
                      <Input
                        className='note-title'
                        value={noteTitle}
                        onChange={(e) => setNoteTitle(e.target.value)}
                      />
                    </Col>

                    <Col span={24}>
                      <TextArea
                        rows={10}
                        placeholder='Enter your note detail'
                        value={noteContent}
                        onChange={(e) => setNoteContent(e.target.value)}
                      />
                    </Col>

                    <div className='note-tag-container'>
                      {
                        currentYear === true &&
                        <div
                          className="tag-button"
                          style={{ backgroundColor: "#FFA877" }}
                        >
                          <span style={{ color: "white" }}>{yearDate}</span>
                        </div>
                      }
                      {
                        currentMonth === true &&
                        <div
                          className="tag-button"
                          style={{ backgroundColor: "#FDF2EC", borderColor: "#FFA877", borderWidth: 1, bottom: 0 }}
                        >
                          <span style={{ color: "#FFA877" }}>{monthDate}</span>
                        </div>
                      }
                      {
                        selectedTag?.map((tag) => (
                          <div
                            className="tag-button"
                            style={{ backgroundColor: tag.color, borderColor: tag.color.replace("88", ""), borderWidth: 1, bottom: 0 }}
                          >
                            <span style={{ color: "white" }}>{tag.text} </span>
                          </div>
                        ))
                      }

                      <Popover
                        content={content}
                        trigger="hover"
                      >
                        <Input
                          value={newTag}
                          placeholder="Add tag"
                          className="new-tag-input"
                          onChange={(e) => {
                            setNewTag(e.target.value)
                            searchInfo(e.target.value)
                          }}
                        />
                      </Popover>
                    </div>
                    <Col span={24}>
                      <Button type="primary" onClick={() => updateNote()} className='update-button'>
                        Update Note
                      </Button>
                    </Col>
                  </Row>
                  :
                  <Row>
                    <Col span={24}>
                      <h1 className='note-title'>{noteData?.title}</h1>
                    </Col>

                    <Col span={24} style={{ marginTop: 20 }}>
                      {
                        noteData?.noteType == "image" ?
                          <img src={noteData.imageUrl} style={{ width: "100%" }} />
                          :
                          <p>{noteData?.content}</p>
                      }
                      <div className='note-tag-container'>
                        {
                          currentYear === true &&
                          <div
                            className="tag-button"
                            style={{ backgroundColor: "#FFA877" }}
                          >
                            <span style={{ color: "white" }}>{yearDate}</span>
                          </div>
                        }
                        {
                          currentMonth === true &&
                          <div
                            className="tag-button"
                            style={{ backgroundColor: "#FDF2EC", borderColor: "#FFA877", borderWidth: 1, bottom: 0 }}
                          >
                            <span style={{ color: "#FFA877" }}>{monthDate}</span>
                          </div>
                        }
                        {
                          selectedTag?.map((tag) => (
                            <div
                              className="tag-button"
                              style={{ backgroundColor: tag.color, borderColor: tag.color.replace("88", ""), borderWidth: 1, bottom: 0 }}
                            >
                              <span style={{ color: "white" }}>{tag.text} </span>
                            </div>
                          ))
                        }
                      </div>
                    </Col>


                    {
                      linkData?.map((links) => (
                        <Col span={24} className='link-previewer-wrapper'>
                          <LinkPreview
                            url={links}
                            width='400px'
                            fetcher={customFetcher}
                          />
                        </Col>
                      ))
                    }

                  </Row>
              }
            </div>
          </Card>
        </Col>
      </Row>
    </HeaderContainer>
  )
}
