const MigrateTagSchema = {
  name: 'migrateTags',
  fields: [
    {
      name: 'version',
      type: 'string',
      facet: false,
    },
    {
      name: 'user',
      type: 'string',
      facet: false,
      index: true,
    },
  ],
};

export default MigrateTagSchema;
