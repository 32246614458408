import React, { useEffect, useState } from 'react'
import HeaderContainer from '../container/HeaderContainer'
import { Button, Col, Input, Row, message } from 'antd'
import "./style.css";
import { getUserData, updateUserSetting } from '../../fb/auth';
import { Navigate, useNavigate } from 'react-router-dom';

export default function ProfileScreen() {

  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();

  const [loading, setLoading] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [userData, setUserData] = useState(null);


  const init = async () => {
    const user = await localStorage.getItem("user");

    if (user) {
      setUserData(user)
      setLoading(true);

      getUserData(user)
        .then((response) => {
          setLoading(false);
          setEmailAddress(response.email);
          setPhoneNumber(response.phoneNumber);
        })
        .catch((err) => {
          setLoading(false);
        })
    } else {
      navigate("/")
    }
  }

  useEffect(() => {
    init();
  }, [])


  const saveChangeData = () => {
    setLoading(true);

    const field = {
      email: emailAddress,
      phoneNumber: phoneNumber,
    }

    updateUserSetting({ user: userData, field: field })
      .then((response) => {
        setLoading(false);
        messageApi.success("Successfully updated profile information!");
      })
      .catch((err) => {
        setLoading(false);
        messageApi.error("Something went wrong, please try again later!");
      })
  }


  return (
    <HeaderContainer loading={loading}>
      {contextHolder}
      <Row>
        <Col
          xs={{ span: 22, offset: 1 }}
          lg={{ span: 8 }}
        >
          <h1 className="page-title">Profile</h1>
        </Col>
      </Row>


      <Row>
        <Col
          xs={{ span: 22, offset: 1 }}
          lg={{ span: 8 }}
          className='input-container'
        >
          <label className='input-label'>Email Address</label>
          <Input
            value={emailAddress}
            onChange={(e) => setEmailAddress(e.target.value)}
            size="large"
            className="profile-input"
            placeholder='Email Address'
          />
        </Col>
      </Row>

      <Row>
        <Col
          xs={{ span: 22, offset: 1 }}
          lg={{ span: 8 }}
          className='input-container'
        >
          <label className='input-label'>Phone Number</label>
          <Input
            size="large"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            className="profile-input"
            placeholder='Phone Number'
          />
        </Col>
      </Row>

      <Row>
        <Col
          xs={{ span: 22, offset: 1 }}
          lg={{ span: 8 }}
          className='input-container'
        >
          <Button className='primary-button' onClick={() => saveChangeData()} type="primary">
            Save
          </Button>
        </Col>
      </Row>


    </HeaderContainer>
  )
}
