import { LogoutOutlined } from '@ant-design/icons';
import { Button, Layout, Menu, Spin } from 'antd';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './header.css';

export default function HeaderContainer({ children, loading }) {

  const { Header, Content } = Layout;
  const navigate = useNavigate();
  const location = useLocation();

  function getItem(label, key, icon, children) {
    return {
      key,
      icon,
      children,
      label
    };
  }

  const items = [
    getItem('Notes', '/home'),
    getItem('Tags', '/tag',),
    getItem('Profile', '/profile',),
    getItem('Settings', '/setting',)
  ];

  const logout = async () => {
    await localStorage.removeItem("user")
    navigate("/");
  }

  return (
    <Layout className="layout">
      <Header className='header-container'>
        <div className="demo-logo">
          <img src={require("../../assets/images/logo.png")} className="header-logo" />
        </div>
        <Menu
          className='menu-container'
          mode="horizontal"
          defaultSelectedKeys={[location.pathname]}
          items={items}
          onClick={(e) => navigate(e.key)}
        />
        <Button
          size='large'
          type="text"
          onClick={() => logout()}
          className='logout-button'
          icon={<LogoutOutlined />}
        >

          Log Out
        </Button>
      </Header>
      <Content className='content-wrapper' style={{ padding: '12px 0' }}>
        {
          loading &&
          <div className='loading-view'>
            <Spin size="large" />
          </div>
        }
        {children}
      </Content>
    </Layout>
  )
}
