const MainTagSchema = {
  name: 'mainTags',
  fields: [
    {
      name: 'color',
      type: 'string',
      facet: false,
    },
    {
      name: 'text',
      type: 'string',
      facet: false,
      sort: true,
    },
    {
      name: 'user',
      type: 'string',
      facet: false,
      index: true,
    },
  ],
};

export default MainTagSchema;
