import { Button, Card, Col, Popover, Row } from 'antd'
import React from 'react'
import './style.css'

export default function NoteCard({ note, goToDetail, goToEdit, removeNote }) {

  const popoverContent = () => {
    return (
      <div>
        <Button type="text" onClick={() => goToEdit(note)}>Edit</Button>
        <Button type="text">Share</Button>
        <Button type="text" onClick={() => removeNote(note.id)}>Delete</Button>
      </div>
    )
  }

  return (
    <Card
      className='note-card-container'
      bordered={false}
    >
      <Row>
        <Col span={24} className='note-card-header-container'>
          <h1
            onClick={() => goToDetail()}
            className='note-card-title'
          >
            {note?.title}
          </h1>
          {/*
                    <Popover
                        placement="bottom"
                        content={popoverContent}
                        trigger="click"
                    >
                        <Button type="link" style={{ paddingRight: 0 }}>
                            <img src={require("../../assets/images/pop_dot.png")} className='more-button' />
                        </Button>
                    </Popover> */}

        </Col>
        <Col span={24}
          onClick={() => goToDetail()}
        >
          {
            note?.noteType == "image" ?
              <img src={note.imageUrl} className='note-image' />
              :
              <p className='note-card-content'>{note?.content}</p>
          }
        </Col>
      </Row>
    </Card>
  )
}
