import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import 'firebase/auth';

export const firebaseConfig = {
  apiKey: "AIzaSyByAonMIG4akjeyHbSbdajePpINO2kRF4Y",
  authDomain: "kernels-prod.firebaseapp.com",
  projectId: "kernels-prod",
  storageBucket: "kernels-prod.appspot.com",
  messagingSenderId: "727058841110",
  appId: "1:727058841110:web:ed0229753b8daa6e0fff37",
  measurementId: "G-NGJF43R0T7"
};

export const FBApp = initializeApp(firebaseConfig);
export const FBDB = getFirestore(FBApp);
export const FBAUTH = getAuth();
