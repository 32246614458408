import { TYPESENSE_API_KEY, TYPESENSE_HOST} from '../Constant';
import MainTagSchema from './schemas/MainTag';
import MigrateTagSchema from './schemas/MigrateTag';
import SubTagSchema from './schemas/SubTag';
import Typesense from 'typesense';

const collectionSchemas = {
  [MainTagSchema.name]: MainTagSchema,
  [MigrateTagSchema.name]: MigrateTagSchema,
  [SubTagSchema.name]: SubTagSchema,
};

const Collections = {
  MainTag: MainTagSchema.name,
  MigrateTag: MigrateTagSchema.name,
  SubTag: SubTagSchema.name,
};

const Client = new Typesense.Client({
  apiKey: TYPESENSE_API_KEY,
  nodes: [
    {
      host: TYPESENSE_HOST,
      port: 443,
      protocol: 'https',
    },
  ],
  connectionTimeoutSeconds: 2,
});

const DropCollection = async (collectionName) => {
  if (!collectionSchemas[collectionName]) {
    return false;
  }
  await Client.collections(collectionName).delete();
};

const InitCollection = async (collectionName) => {
  if (!collectionSchemas[collectionName]) {
    return false;
  }
  return await Client.collections()
    .retrieve()
    .then(async (collections) => {
      const collection = collections?.find((c) => c.name === collectionName);
      if (!!collection) {
        return true;
      }
      console.info('Initialize Collection', collectionName);
      return await Client.collections()
        .create(collectionSchemas[collectionName])
        .then(() => {
          console.info('Success to create `' + collectionName + '`.');
          return true;
        })
        .catch((e) => {
          console.error(e);
          throw new Error('Failed to create `' + collectionName + '`.');
        });
    })
    .catch((e) => {
      console.error(e);
      throw new Error('Failed to retrieve all collections.');
    });
};

const rebuildAllCollections = async () => {
  await Promise.all(Object.keys(Collections).map(async (key) => await DropCollection(Collections[key])));
  await Promise.all(Object.keys(Collections).map(async (key) => await InitCollection(Collections[key])));
};

const createDocument = async (collectionName, document) =>
  await Client.collections(collectionName)
    .documents()
    .create(document)
    .then((doc) => {
      console.info('Success to create document for `' + collectionName + '`...');
      return doc;
    })
    .catch((e) => {
      console.error(e);
      throw new Error('Failed to create document for `' + collectionName + '`...');
    });

const updateDocument = async (collectionName, id, document) =>
  await Client.collections(collectionName)
    .documents(id)
    .update(document)
    .then(() => {
      console.log('Success to update document for', id, collectionName);
      return true;
    })
    .catch((e) => {
      console.error(e);
      throw new Error(`Failed to delete document for ${id} - ${collectionName}`);
    });

const deleteDocument = async (collectionName, id) =>
  await Client.collections(collectionName)
    .documents(id)
    .delete()
    .then(() => {
      console.info('Success to delete document for ', id, collectionName);
      return true;
    })
    .catch((e) => {
      console.error(e);
      throw new Error(`Failed to delete document for ${id} - ${collectionName}`);
    });

const retrieveDocument = async (collectionName, id) =>
  await Client.collections(collectionName)
    .documents(id)
    .retrieve()
    .then((doc) => {
      console.info('Success to retrieve document for', id, collectionName);
      return doc;
    })
    .catch((e) => {
      console.error(e);
      throw new Error(`Failed to retrieve document for ${id} - ${collectionName}`);
    });

const searchDocument = async (collectionName, query, queryBy, sortBy, filter_by) => {
  return Client.collections(collectionName)
    .documents()
    .search({
      q: query,
      query_by: queryBy,
      sort_by: sortBy,
      filter_by: filter_by,
      per_page: 250,
    });
  }

export {
  Collections,
  createDocument,
  deleteDocument,
  rebuildAllCollections,
  retrieveDocument,
  searchDocument,
  updateDocument,
};
