import { getRandomColor } from './CommonService';
import { Collections, createDocument, searchDocument } from './Typesense';

export const addMainTag = async (tag) => {
  if (!tag) {
    return false;
  }

  try {
    const { found, hits } = await searchDocument(Collections.MainTag, tag.text || tag.name, 'text', null, `user:=${tag.user}`);
    if (!!found) {
      return { ...hits[0].document };
    }

    const doc = await createDocument(Collections.MainTag, {
      color: tag.color || getRandomColor(),
      text: tag.text || tag.name,
      user: tag.user,
    });

    console.log('Main Tag added!');
    console.log(doc);
    return { ...doc };
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const existsInMainTags = async (tag) => {
  if (!tag) {
    return false;
  }

  try {
    console.log(tag.text);
    const { found, hits } = await searchDocument(Collections.MainTag, tag.text, 'text', null, `user:=${tag.user}`);
    console.log(hits);
    return !!found ? { ...hits[0].document } : false;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const getAllTags = async (user, checkbox, merge) => {
  const checkboxData = [];

  const result = {
    subTags: [],
    mainTags: [],
  };

  if (!user) {
    return checkbox && merge ? checkboxData : result;
  }

  try {
    const { hits: mainTags } = await searchDocument(Collections.MainTag, user, 'user', 'text:asc');
    for (const mainTag of mainTags) {
      const mainDoc = mainTag.document;
      const { hits: subTags } = await searchDocument(Collections.SubTag, mainTag.document.id, 'mainTag', 'text:asc');
      if (checkbox) {
        const data = {
          id: mainDoc.id,
          text: mainDoc.text,
          fillColor: mainDoc.color,
          parent: true,
          showText: mainDoc.text,
          hasSub: Boolean(subTags && subTags.length > 0),
        };
        if (merge) {
          checkboxData.push(data);
        } else {
          result.mainTags.push(data);
        }
      } else {
        result.mainTags.push(mainTag);
      }

      (subTags || []).map(({ document: subTag }) => {
        if (checkbox) {
          const data = {
            id: subTag.id,
            text: subTag.text,
            fillColor: subTag.color,
            parent: false,
            parentId: mainDoc.id,
            parentText: mainDoc.text,
            showText: mainDoc.text + '/' + subTag.text,
          };
          if (merge) {
            checkboxData.push(data);
          } else {
            result.subTags.push(data);
          }
        } else {
          result.subTags.push(subTag);
        }
      });
    }

    return checkbox && merge ? checkboxData : result;
  } catch (e) {
    console.log(e);
    return false;
  }
};
