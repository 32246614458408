import { Button, Col, Input, Modal, Row, Select, message } from 'antd';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import HeaderContainer from '../container/HeaderContainer';
import "./style.css";
import { createMainTag, createSubTag, getSubTag, getTagData, removeMainTag, removeSubTag } from '../../fb/note';
import { addMainTag, getAllTags } from '../../services/MainTagService';
import { Collections, deleteDocument, searchDocument } from '../../services/Typesense';
import { addSubTag } from '../../services/SubTagService';
import { getRandomColor } from '../../services/CommonService';

export default function TagScreen() {

  const navigate = useNavigate();
  const { Option } = Select;
  const [messageApi, contextHolder] = message.useMessage();

  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const [tagData, setTagData] = useState([]);
  const [subTagData, setSubTagData] = useState([]);
  const [createModal, setCreateModal] = useState(false);
  const [scrollEnable, setScrollEnable] = useState(true);
  const [newTagText, setNewTagText] = useState("");
  const [selectedParent, setSelectedParent] = useState(null);

  const init = async () => {
    const user = await localStorage.getItem("user");
    if (user) {
      setLoading(true);
      setUserData(user);

      const tags = await getAllTags(user, true, true);

      setTagData(tags);
      setSubTagData(tags);

      setLoading(false);
    }
    else {
      navigate("/");
    }

  }

  useEffect(() => {
    init();
  }, []);

  const cancelTag = async (tag) => {

    setLoading(true);

    if (tag.parent) {
      try {
        await deleteDocument(Collections.MainTag, tag.id);
        await searchDocument(Collections.SubTag, tag.id, 'mainTag').then(async ({ hits: subTags }) => {
          await Promise.all(
            (subTags ?? []).map(async ({ document: subTag }) => await deleteDocument(Collections.SubTag, subTag.id)),
          );
        });
        setLoading(false);
      } catch (e) {
      }
    } else {
      await deleteDocument(Collections.SubTag, tag.id)
        .then(() => {
          setLoading(false);
          init();
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }

  const createTag = async () => {

    if (newTagText === "") {
      alert("Please input tag name!");
      return;
    }
    try {
      setLoading(true);
      if (selectedParent) {
        const selectedItem = tagData.find(element => element.id == selectedParent);
        console.log(selectedItem);
        const param = {
          color: selectedItem.fillColor + "88",
          text: newTagText,
          mainTag: selectedItem.id
        }

        if (await addSubTag(param, selectedItem)) {
          setNewTagText("");
          setCreateModal(false);
          setLoading(false);
          setSelectedParent(null);
          init();
        }
      } else {
        const param = {
          color: getRandomColor(),
          text: newTagText,
          user: userData
        }
        await addMainTag(param);
        setNewTagText("");
        setCreateModal(false);
        setLoading(false);
        init();
      }
    } catch (e) {
      setLoading(false);
      messageApi.error("Something went wrong, please try again later!");
    }

  }


  return (
    <HeaderContainer loading={loading}>
      {contextHolder}

      <Row>
        <Col span={22} offset={1}>
          <div className='top-container'>

            <h1 className='page-title'>Tags</h1>
            <Button type='link' style={{ height: "100%" }} onClick={() => setCreateModal(true)}>
              <img src={require("../../assets/images/add-icon.png")} className='add-button' alt="add-btton" />
            </Button>
          </div>

          <Row style={{ flexDirection: "column" }}>
            {
              tagData.map((tag, index) => (
                <>
                  {
                    index !== 0 && tag.parent &&

                    <div className='seperate-line'></div>

                  }
                  <Col
                    className='tag-container'
                    span={24}
                    key={index}
                    style={{
                      paddingTop: 5,
                      paddingBottom: 5,
                      marginBottom: index === tagData.length - 1 ? 20 : null
                    }}
                  >
                    <div style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      paddingTop: 5,
                      borderColor: "#CCC",
                      width: "100%"
                    }}>

                      <div style={{ display: "flex" }}>
                        <span style={{ marginRight: 5, width: 20, fontSize: 16, color: "#888" }}>
                          {
                            tag.parent ? tag.hasSub ? "" : "=" : "="
                          }
                        </span>
                        <div
                          style={{
                            width: 20,
                            height: 20,
                            backgroundColor: tag.fillColor,
                            borderRadius: 100,
                            marginRight: 10,
                            borderColor: !tag.parent ? tag.fillColor : tag.fillColor.slice(0, 7),
                            borderWidth: 2,
                          }}>

                        </div>
                        <span
                          style={{
                            fontSize: 14,
                            color: "#000",
                            fontWeight: tag.parent ? "bold" : null
                          }}>
                          {
                            tag.showText
                          }
                        </span>
                      </div>
                      <Button
                        type='link'
                        onClick={() => { cancelTag(tag) }}
                        style={{ justifyContent: 'center' }}
                      >
                        <img
                          style={{ height: 10, marginRight: 20 }}
                          src={require("../../assets/images/cancel-icon.png")}
                          alt='cancel-button'
                        />
                      </Button>
                    </div>
                  </Col>
                </>

              ))
            }
          </Row>
        </Col>
      </Row>

      <Modal
        title="Create Tag"
        open={createModal}
        okText="Create"
        onOk={createTag}
        onCancel={() => setCreateModal(false)}
      >
        <Row>
          <Col span={24}>
            <Input
              placeholder='Input new tag name'
              value={newTagText}
              onChange={(e) => setNewTagText(e.target.value)}
            />
          </Col>

          <Col span={24}>
            <Select
              style={{ width: "100%", marginTop: 16 }}
              onChange={(e) => setSelectedParent(e)}
              value={selectedParent}
              placeholder="Choose nested tag if needed"
            >
              {tagData.map(tag => (
                tag.parent &&
                <Option key={tag.id} value={tag.id} label={tag.text}>
                  {tag.text}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
      </Modal>
    </HeaderContainer>
  )
}
