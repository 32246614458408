import { Collections, createDocument, searchDocument } from './Typesense';

export const addSubTag = async (subTag, mainTag) => {
  if (!subTag || !mainTag) {
    return false;
  }

  const { text: tagName } = subTag;

  try {
    const { found, hits } = await searchDocument(Collections.SubTag, tagName, 'text', null, `mainTag:=${mainTag.id}`);
    if (!!found) {
      const doc = hits[0].document;
      return { ...doc };
    }

    const docs = await createDocument(Collections.SubTag, {
      color: subTag.color,
      text: tagName,
      mainTag: mainTag.id,
      fid: subTag.id ? subTag.id : ''
    });

    console.log('Sub Tag added!');
    console.log(docs);
    return { ...docs };
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const existsInSubTags = async (tag) => {
  if (!tag) {
    return false;
  }

  try {
    const { found, hits } = await searchDocument(Collections.SubTag, tag.text, 'text', null, `mainTag:=${tag.mainTag}`);
    return !!found ? { ...hits[0].document } : false;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const getSubTagByFid = async (tag) => {
  try {
    const { found, hits } = await searchDocument(Collections.SubTag, tag.text, 'text', null, `fid:=${tag.id}`);
    return !!found ? { ...hits[0].document } : false;
  } catch (err) {
    console.log(err);
    return false;
  }
};
