import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, orderBy, query, updateDoc, where } from 'firebase/firestore';
import { FBDB } from './init';

export const getUserNotes = async (user) => {
  try {
    const q = query(collection(FBDB, "notes"), where("user", "==", user), orderBy("createdDate", "desc"));
    const querySnapshot = await getDocs(q);
    return querySnapshot;
  } catch (error) {
    console.log(error);
    return {};
  }
}

export const getNoteDetail = async (param) => {
  try {
    const docRef = doc(FBDB, "notes", param.id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return { ...docSnap.data(), id: docSnap.id, ref: docSnap.ref };
    } else {
      return {};
    }
  } catch (error) {
    console.log(error);
    return {};
  }
}

export const getTagData = async (user) => {
  try {
    const q = query(collection(FBDB, "mainTag"), where("user", "==", user));
    const querySnapshot = await getDocs(q);
    const noteData = querySnapshot.docs
      .map((doc) => ({ ...doc.data(), id: doc.id }));
    return noteData;
  } catch (error) {
    console.log(error);
    return {};
  }
}

export const getSubTag = async (id) => {
  try {
    const q = query(collection(FBDB, "subTag"), where("mainTag", "==", id));
    const querySnapshot = await getDocs(q);
    const noteData = querySnapshot.docs
      .map((doc) => ({ ...doc.data(), id: doc.id }));
    return noteData;
  } catch (error) {
    console.log(error);
    return {};
  }
}

export const removeNoteData = async (id) => {
  try {
    await deleteDoc(doc(FBDB, "notes", id));
    return { status: "success" }
  }
  catch (err) {
    return null
  }
}

export const removeMainTag = async (id) => {
  try {
    await deleteDoc(doc(FBDB, "mainTag", id));

    try {
      const q = query(collection(FBDB, "subTag"), where("mainTag", "==", id));
      const querySnapshot = await getDocs(q);
      const noteData = querySnapshot.docs
        .map((doc) => {
          removeSubTag(doc.id);
        });
      return { status: "success" }
    } catch (error) {
      console.log(error);
      return {};
    }

  }
  catch (err) {
    return null
  }
}

export const removeSubTag = async (id) => {
  try {
    await deleteDoc(doc(FBDB, "subTag", id));
    return { status: "success" }
  }
  catch (err) {
    return null
  }
}

export const createMainTag = async (param) => {
  try {
    const docRef = await addDoc(collection(FBDB, "mainTag"), param);
    return docRef.id;
  }
  catch (err) {
    console.log(err);
    return null;
  }
}

export const createSubTag = async (param) => {
  try {
    const docRef = await addDoc(collection(FBDB, "subTag"), param);
    return docRef.id;
  }
  catch (err) {
    console.log(err);
    return null;
  }
}

export const createNewNote = async (param) => {
  try {
    const docRef = await addDoc(collection(FBDB, "notes"), param);
    return docRef.id;
  }
  catch (err) {
    console.log(err);
    return null;
  }
}

export const updateNoteData = async (param, id) => {
  try {
    const ref = doc(FBDB, "notes", id)
    const result =await updateDoc(ref, param);

    return { status: "success" };
  } catch (err) {
    console.log(err);
    return { status: "failed", err: err };
  }
}
