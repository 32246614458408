import { Button, Col, Row, Switch, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import HeaderContainer from '../container/HeaderContainer';
import "./style.css";
import { getUserData, updateUserSetting } from '../../fb/auth';

export default function SettingScreen() {

    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();

    const [loading, setLoading] = useState(false);
    const [userData, setUserData] = useState(null);
    const [currentYear, setCurrentYear] = useState(false);
    const [currentMonth, setCurrentMonth] = useState(false);

    const init = async () => {
        const user = await localStorage.getItem("user");
        if (user) {
            setLoading(true);
            setUserData(user);
            getUserData(user)
                .then((response) => {
                    const userData = response;
                    setCurrentYear(userData.yearTagVisible);
                    setCurrentMonth(userData.monthTagVisible);
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                    console.log(err);
                    messageApi.error("Something went wrong while fetching data, please try again later!");
                })
        }
        else {
            navigate("/");
        }

    }

    useEffect(() => {
        init();
    }, [])


    const saveChangeData = (field, status) => {
        setLoading(true);
        var setField;
        if (field == "year") {
            setCurrentYear(status);
            setField = {
                yearTagVisible: status
            }
        } else {
            setCurrentMonth(status)
            setField = {
                monthTagVisible: status
            }
        }

        updateUserSetting({ user: userData, field: setField })
            .then((response) => {
                messageApi.success("Success!");

                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
                messageApi.error("Something went wrong, please try again later!");
            })
    }



    return (
        <HeaderContainer loading={loading}>
            {contextHolder}
            <Row>
                <Col
                    span={22}
                    offset={1}
                >
                    <Row>
                        <Col span={24}>
                            <h1 className="page-title">Settings</h1>
                        </Col>
                        <Col span={24}>
                            <h1 className='sub-title'>Tags</h1>
                            <span className='sub-description'>Automatically tag a new note with:</span>
                        </Col>

                        <Col span={24} className='swtich-container'>
                            <Switch checked={currentYear} onChange={(e) => saveChangeData("year", e)} />
                            <span className='setting-switch-text'>Current Year</span>
                        </Col>

                        <Col span={24} className='swtich-container'>
                            <Switch checked={currentMonth} onChange={(e) => saveChangeData("month", e)} />
                            <span className='setting-switch-text'>Current Month</span>
                        </Col>
                    </Row>

                    <div className='separate-line'></div>

                    <Row className='setting-wrapper'>
                        <Col span={24}>
                            <h1 className='sub-title'>Activity Log</h1>
                            <Button type="link" className='setting-button'>View app activity</Button>
                        </Col>
                    </Row>

                    <div className='separate-line'></div>

                    <Row className='setting-wrapper'>
                        <Col span={24}>
                            <h1 className='sub-title'>Tell a Friend</h1>
                            <Button type="link" className='setting-button'>Share Kernels</Button>
                        </Col>
                    </Row>

                </Col>
            </Row>
        </HeaderContainer>
    )
}
