import React, { useEffect, useState } from 'react'
import HeaderContainer from '../container/HeaderContainer'
import { useNavigate } from 'react-router-dom';
import { Button, Card, Col, Input, Popover, Row, message } from 'antd';
import "./style.css";
import moment from 'moment';
import TextArea from 'antd/es/input/TextArea';
import { createMainTag, createNewNote, getSubTag, getTagData } from '../../fb/note';
import { getUserData } from '../../fb/auth';

export default function NewNote() {

  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();

  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const [noteTitle, setNoteTitle] = useState("");
  const [noteContent, setNoteContent] = useState("");
  const [tagData, setTagData] = useState([]);
  const [subTagData, setSubTagData] = useState([]);
  const [newTag, setNewTag] = useState("");
  const [currentYear, setCurrentYear] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [monthDate, setMonthDate] = useState(moment().format("MMMM"));
  const [yearDate, setYearDate] = useState(new Date().getFullYear());
  const [selectedTag, setSelectedTag] = useState([]);


  const getUser = (user) => {
    getUserData(user)
      .then((response) => {
        setCurrentYear(response.yearTagVisible);
        setCurrentMonth(response.monthTagVisible);
      })
      .catch((err) => {
        console.log(err);
        messageApi.error("Something went wrong, please try again later!");
      })

  }

  const init = async () => {
    const user = await localStorage.getItem("user");
    if (user) {
      setLoading(true);
      setUserData(user);
      getUser(user);

      setTagData([]);
      setSubTagData([]);

      getTagData(user)
        .then(async (response) => {
          await Promise.all(response.map(async (doc) => {
            const data = doc;
            try {
              getSubTag(doc.id)
                .then((response) => {

                  const checkboxData = {
                    id: doc.id,
                    text: data.text,
                    fillColor: data.color,
                    parent: null,
                    showText: data.text
                  };
                  setTagData((origin) => [...origin, checkboxData]);

                  response.map(async (docs) => {
                    const subData = docs;
                    const subcheckboxData = {
                      id: docs.id,
                      text: subData.text,
                      fillColor: subData.color,
                      parentID: doc.id,
                      parent: data.text,
                      showText: data.text + "/" + subData.text
                    };
                    setTagData((origin) => [...origin, subcheckboxData]);
                  })
                })
                .catch((err) => {
                  console.log(err);
                })
            } catch (e) {
              setLoading(false);
              messageApi.error("Something went wrong, please try again later!");
            }
          }))
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          messageApi.error("Something went wrong, please try again later!");
        })
    }
    else {
      navigate("/");
    }

  }


  useEffect(() => {
    init();
  }, [])

  const content = () => {
    return (
      searchData.length > 0 ?
        <div className='pop-tag-container'>
          {
            searchData.map((search, index) => (
              <Button
                key={index}
                type="link"
                onClick={() => addExistingTag(search)}
              >
                {search.showText}
              </Button>
            ))
          }
        </div>
        :
        <div className='pop-tag-container'>
          {
            newTag ?
              <Button
                type="link"
                onClick={() => addNewTag()}
              >
                Add Tag {newTag}
              </Button>
              :
              <></>
          }
        </div>
    )
  }

  const searchInfo = (e) => {
    const result = [];
    if (e != "") {
      tagData.map((tag) => {
        if (tag.showText.toLowerCase().includes(e.toLowerCase()) === true) {
          result.push(tag);
        }
      })
      setSearchData(result);
    } else {
      setSearchData([])
    }
  }


  const addExistingTag = (data) => {
    if (data.parent == null) {
      const tags = {
        text: data.showText,
        color: data.fillColor,
        id: data.id
      }
      setSelectedTag((origin) => [...origin, tags]);
    } else {
      if (!selectedTag.find(element => element.id === data.parentID)) {
        var tags = {
          text: data.parent,
          color: data.fillColor.replace('88', ''),
          id: data.parentID
        }
        setSelectedTag((origin) => [...origin, tags]);
      }
      var tags = {
        text: data.text,
        color: data.fillColor,
        id: data.id
      }
      setSelectedTag((origin) => [...origin, tags]);
    }

    setNewTag("");
  }

  const getRandomColor = () => {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  const addNewTag = () => {
    setLoading(true);
    const color = getRandomColor();

    const data = {
      color: color,
      text: newTag,
      user: userData
    }

    createMainTag(data)
      .then((response) => {
        data.id = response;
        setSelectedTag((origin) => [...origin, data]);
        setNewTag("");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        messageApi.error("Something went wrong, please try again later!");
      })

  }





  const saveBack = (stayStatus) => {
    if (noteTitle == "" && noteContent != "") {
      messageApi.warning("Missing Note Title");
      return;
    }

    if (noteTitle != "" && noteContent == "") {
      messageApi.warning("Missing note content");

    }

    if (noteTitle != "" && noteContent != "") {
      setLoading(true);
      const selectedTagData = JSON.stringify(selectedTag);
      const insertData = {
        user: userData,
        title: noteTitle,
        content: noteContent,
        tag: selectedTagData,
        currentYear: currentYear,
        currentMonth: currentMonth,
        createdDate: new Date()
      }

      createNewNote(insertData)
        .then((response) => {
          console.log(response);
          setLoading(false);
          navigate("/home");
        })
        .catch((err) => {
          console.log(err);
        })
    }
  }

  return (
    <HeaderContainer loading={loading}>
      {contextHolder}


      <Row className="note-detail-container">
        <Col
          xs={{
            span: 22,
            offset: 1
          }}
          lg={{
            span: 12,
            offset: 6
          }}
        >
          <Card
            size="large"
          >
            <Row>
              <Col span={24}>
                <img src={require("../../assets/images/wave.png")} className='wave-image' />
              </Col>
              <Col span={24} className='note-top-container'>
                <Button onClick={() => navigate("/home")} type="link">
                  <img src={require("../../assets/images/back.png")} className='back-icon' />
                </Button>

                <span className='current-date-text'>{moment().format('MMMM Do YYYY')}</span>

                <Button type="link" onClick={() => saveBack()}>
                  Save
                </Button>

              </Col>
            </Row>
            <div className='detail-container'>
              <Row>
                <Col span={24}>
                  <Input
                    className='note-title'
                    placeholder="Title"
                    value={noteTitle}
                    onChange={(e) => setNoteTitle(e.target.value)}
                  />
                </Col>

                <Col span={24}>
                  <TextArea
                    rows={10}
                    placeholder='Enter your note detail'
                    value={noteContent}
                    onChange={(e) => setNoteContent(e.target.value)}
                  />
                </Col>

                <div className='note-tag-container'>

                  {
                    currentYear === true &&
                    <div
                      className="tag-button"
                      style={{ backgroundColor: "#FFA877" }}
                    >
                      <span style={{ color: "white" }}>{yearDate}</span>
                    </div>
                  }
                  {
                    currentMonth === true &&
                    <div
                      className="tag-button"
                      style={{ backgroundColor: "#FDF2EC", borderColor: "#FFA877", borderWidth: 1, bottom: 0 }}
                    >
                      <span style={{ color: "#FFA877" }}>{monthDate}</span>
                    </div>
                  }


                  {
                    selectedTag?.map((tag) => (
                      <div
                        className="note-tag-button"
                        style={{ backgroundColor: tag?.color, borderColor: tag?.color.replace("88", ""), borderWidth: 1, bottom: 0 }}
                      >
                        <span style={{ color: "white" }}>{tag?.text} </span>
                      </div>
                    ))
                  }

                  <Popover
                    content={content}
                    trigger="hover"
                  >
                    <Input
                      value={newTag}
                      placeholder="Add tag"
                      className="new-tag-input"
                      onChange={(e) => {
                        setNewTag(e.target.value)
                        searchInfo(e.target.value)
                      }}
                    />
                  </Popover>
                </div>
              </Row>
            </div>
          </Card>
        </Col>
      </Row>
    </HeaderContainer>
  )
}
