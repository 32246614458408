import { Button, Input, message } from 'antd'
import { RecaptchaVerifier, getAuth, signInWithPhoneNumber } from 'firebase/auth'
import React, { useState } from 'react'
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { useNavigate } from 'react-router-dom'
import { SetUserInfo } from '../../services/GlobalService'
import "./login.css"

export default function LoginScreen() {

  const [phoneNumber, setPhoneNumber] = useState("")
  const [otpVisible, setOtpVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otpCode, setOtpCode] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();

  const auth = getAuth();

  const onOTPSubmit = () => {
    setLoading(true);
    const confirmationResult = window.confirmationResult;

    confirmationResult.confirm(otpCode).then((result) => {
      const user = result.user;
      setLoading(false);
      // localStorage.setItem("user", user.uid);
      // localStorage.setItem("userinfo", JSON.stringify(user));
      SetUserInfo( user );
      window.location.replace("/home");
    }).catch((error) => {
      console.log(error);
      setLoading(false);
      messageApi.error("Invalid verification code. Please check again!");
    });
  }

  const onSignInSubmit = () => {
    const appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setLoading(false);
        setOtpVisible(true);
      }).catch((error) => {
        setLoading(false);
        console.log("error", error);
        messageApi.error("Something went wrong, please try again later!");
      });
  }

  const onLogin = async () => {
    setLoading(true);
    window.recaptchaVerifier = await new RecaptchaVerifier(auth, 'window-recapcha', {
      'size': 'invisible',
      'callback': (response) => {
      }
    });
    onSignInSubmit();
  }

  return (
    <>
      {contextHolder}
      {
        otpVisible ?
          <div className="phone-container">
            <h1 className="sign-title">Sign In</h1>
            <p className='sign-sub-title'>Enter verification code sent to {phoneNumber}</p>
            <Input
              placeholder="Enter Verification Code"
              value={otpCode}
              size="large"
              onChange={(e) => setOtpCode(e.target.value)}
            />
            <Button
              className='login-button'
              size="large"
              type="primary"
              loading={loading}
              disabled={otpCode.length > 5 ? false : true}
              onClick={() => onOTPSubmit()}
            >
              Submit
            </Button>
          </div>
          :
          <div className="phone-container">
            <h1 className="sign-title">Sign In</h1>
            <p className='sign-sub-title'>Enter your phone number</p>
            <PhoneInput
              placeholder="Enter phone number"
              value={phoneNumber}
              onChange={(val) => {
                if (val) setPhoneNumber(val)
              }}
              defaultCountry='US'
            />
            <Button
              className='login-button'
              size="large"
              type="primary"
              loading={loading}
              disabled={isPossiblePhoneNumber(phoneNumber) ? false : true}
              onClick={() => onLogin()}
            >
              Submit
            </Button>
            <div id='window-recapcha'></div>
          </div>
      }
    </>
  )
}
