import { initializeApp } from "firebase/app";
import { onAuthStateChanged } from "firebase/auth";
import React, { useEffect } from "react";
import {
  RouterProvider
} from "react-router-dom";
import { router } from "./Router";
import { FBAUTH, firebaseConfig } from "./fb/init";
import ReactGA from 'react-ga4';

function App() {

  initializeApp(firebaseConfig);
  ReactGA.initialize('G-NGJF43R0T7');

  useEffect(() => {
    onAuthStateChanged(FBAUTH, (user) => {
      if (user) {
        localStorage.setItem("user", user.uid);
      }
    })
  }, [])

  return (
    <RouterProvider router={router} />
  );
}

export default App;
