import { collection, getDocs, getDoc, doc, query, where, updateDoc } from 'firebase/firestore';
import { FBDB } from './init';


export const getUserData = async (user) => {
  try {
    const docRef = doc(FBDB, "users", user);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return { ...docSnap.data(), id: docSnap.id, ref: docSnap.ref };
    } else {
      return {};
    }
  } catch (error) {
    console.log(error);
    return {};
  }
}


export const updateUserSetting = async (param) => {
  try {
    const ref = doc(FBDB, "users", param.user)
    return updateDoc(ref, param.field).then(response => {
      return { status: "success" }
    }).catch(error => {
      return { status: "failed", err: error }
    })

  } catch (err) {
    console.log(err);
    return { status: "failed", err: err };
  }
}
