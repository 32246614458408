import { getSubTag, getTagData, getUserNotes, updateNoteData } from "../fb/note";
import { addMainTag, existsInMainTags, getAllTags } from "./MainTagService";
import { done2MigrateTags, need2MigrateTags } from "./MigrateTagService";
import { addSubTag, existsInSubTags, getSubTagByFid } from "./SubTagService";

const GetUserInfo = () => {
  const str_user = localStorage.getItem('user', '');

  return str_user;
};

const SetUserInfo = (user) => {
  localStorage.setItem('user', user.uid);
};

const SyncTags = async (user) => {
  // check versions
  const b_migrate = await need2MigrateTags(user);

  if (b_migrate) {
    // get all tags from the firebase
    const tags = await getTagData(user);
    for (const tag of tags) {
      let tag_main_t = await existsInMainTags(tag);
      if (tag_main_t) {
        console.log('already exist ', tag);
      } else {
        tag_main_t = await addMainTag(tag);
      }
      // update sub tags
      const sub_tags = await getSubTag(tag.id);
      console.log(sub_tags);
      for (const sub_tag of sub_tags) {
        const sub_tags_t = await addSubTag(sub_tag, tag_main_t);
        console.log(sub_tags_t);
      }
    }
    // get all notes from the firebase
    const notes = await getUserNotes(user);
    for (const note of notes.docs) {
      const tags = note.data().tag;
      const tag_json = JSON.parse(tags || '[]');
      const newTags = [];
      for (const t of tag_json) {
        t.user = user;
        const newMainTag = await existsInMainTags(t);
        if (newMainTag) {
          newTags.push(newMainTag);
        } else {
          const newSubTag = await getSubTagByFid(t);
          newSubTag && newTags.push(newSubTag);
        }
      }
      await updateNoteData({ tag: JSON.stringify(newTags) }, note.id);
    }
    // update typesense db
    await done2MigrateTags(user);
  }
  return true;
};

export {
  GetUserInfo,
  SetUserInfo,
  SyncTags
};
