import { Collections, createDocument, searchDocument } from './Typesense';
import { VERSION } from '../Constant';

export const need2MigrateTags = async (user) => {
  if (!user) {
    return false;
  }

  try {
    const { found } = await searchDocument(Collections.MigrateTag, user, 'user', null, `version:=${VERSION}`);

    return found === 0;
  } catch (e) {
    console.log(e);
    return false;
  }

};

export const done2MigrateTags = async (user) => {
  if (!user) {
    return false;
  }

  try {
    const doc = await createDocument(Collections.MigrateTag, {
      version: VERSION,
      user: user,
    });

    console.log('Migrated with', user, 'for', 'version', VERSION);
    return { ...doc };
  } catch (e) {
    console.log(e);
    return false;
  }
};
